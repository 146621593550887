import React, { useState } from "react";
import Accorcomp from "../component/Accorcomp";
import Comp1 from "../component/allcomps/Comp1";
import Comp2 from "../component/allcomps/Comp2";
import Comp3 from "../component/allcomps/Comp3";
import Comp4 from "../component/allcomps/Comp4";
import Comp5 from "../component/allcomps/Comp5";
import Comp6 from "../component/allcomps/Comp6";
import "../component/allcomps/css/accorcomp.css";

const compElements = ["The Best Portal", "Zoom Live Classes", "Doubt Solving", "Resume Guidance","Placement Assistance","Course Roadmaps"];

const RenderComp = ({ index }) => {
  switch (index) {
    case 0:
      return <Comp1 />;
      break;
    case 1:
      return <Comp2 />;
      break;
    case 2:
      return <Comp3 />;
      break;
    case 3:
      return <Comp4 />;
      break;
    case 4:
      return <Comp5 />;
      break;
    case 5:
      return <Comp6 />;
      break;
    default:
      break;
  }
};

function Renderelement() {
  const [isSelected, setIsselected] = useState(0);
  return (
    <div className="render-element">
      <Accorcomp
        compElements={compElements}
        isSelected={isSelected}
        setIsselected={setIsselected}
      />
      <RenderComp index={isSelected} />
    </div>
  );
}

export default Renderelement;
