import React from "react";
import testimage from "../../../../images/services pics/testimage2.png";
import Childcomp from "../Childcomp";

const itemsmain = {
  title: "Our Live Zoom classes!",
  subtitle: "AI powered Zoom classes to drive quality teaching?",
  para: "With live coaching, assessments, learning paths & courses authored by industry experts, our platform helps individuals build expertise in chosen paths without any barriers & achieve your career goals.",
  image: testimage,
};

function Comp2() {
  return (
    <div>
      <Childcomp data={itemsmain} />
    </div>
  );
}

export default Comp2;
