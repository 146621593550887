import React, { useEffect, useState } from "react";
import clock from "../../images/clock.png";
import status from "../../images/status.png";
import certificate from "../../images/certificate.png";
import share from "../../images/share.png";
import fire from "../../images/fire.png";
import StartRating from "../StartRating";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import iso from "../../images/9k.png";
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../components/home/homecard.css"

const HomeCardItem = (props) => {
  const [propsObj, setPropsObj] = useState();

  const redirectToCourseDetails = (id, title) => {
    window.open(`/live?${title.replaceAll(" ", "-")}=` + id, "_blank");
    // window.open("/courseDetail?course_info=" + id, "_blank");
  };

  // share button logic
  const { upcomingWorkshop } = useSelector((state) => state.upcoingWorkshop);

  const dispatch = useDispatch();
  const shareLink = (link) => {
    //window.location=link;
    window.open(link, "_blank");
  };

  const courseSpecificData = [
    {
      course: "Professional Certificate Program in Data Science",
      hours: "120 hrs",
      months: "5 Months",
      sessions: 48,
    },
    {
      course: "Professional Certificate Program in  Business Analytics",
      hours: "120 hrs",
      months: "5 Months",
      sessions: 48,
    },
    {
      course: "Complete Data Analytics Course",
      hours: "100 hrs",
      months: "4 Months",
      sessions: 40,
    },
    {
      course: "AWS Certified Cloud Practitioner - Complete Course",
      hours: "100 hrs",
      months: "4 Months",
      sessions: 40,
    },
  ];

  return (
    <div className="card">
      {/* <LazyLoadImage src={iso} alt="iso" className="card_iso" /> */}
      <div className="card-img">
        <LazyLoadImage
          src={props.upcomingCourse.course_image}
          width="100%"
          // height="100%"
          alt="card img"
        />
      </div>
      <div className="card-img-overlay h-25 d-flex  justify-content-between">
        {props.upcomingCourse.category ? (
          <div className="overlay-wrapper">
            <span className="tech-btn">{props.upcomingCourse.category}</span>
          </div>
        ) : (
          <div className="overlay-wrapper">
            <span className="live-btn">Live</span>
            <span className="popular-btn">
              <LazyLoadImage src={fire} alt="fire" /> Popular
            </span>
            <br />
          </div>
        )}
        <div>
          <LazyLoadImage
            src={share}
            alt="share"
            className="share-img"
            onClick={() =>
              shareLink(
                "https://api.whatsapp.com/send?text=" +
                  "Hey! Checkout this amazing course " +
                  props.upcomingCourse.title +
                  " By " +
                  // props.upcomingCourse.edu_info.user_info.first_name +
                  " on CoachX " +
                  props.upcomingCourse.share_link
              )
            }
          />
        </div>
      </div>
      <div className="card-inner">
        {props.upcomingCourse?.title?.length > 44 ? (
          <h5 className="card-title title mx-2">{props.upcomingCourse.title.slice(0, 60)}..</h5>
        ) : (
          <h5 className="card-title title mx-2">{props.upcomingCourse.title}</h5>
        )}
        <div className="d-flex course-info flex-column mx-2 py-0">
          {props.upcomingCourse.description.length > 70 ? (
            <p className="my-3">{props.upcomingCourse.description.slice(0, 150)}...</p>
          ) : (
            <p className="my-3">{props.upcomingCourse.description}</p>
          )}
          {/* <span className="courseBy my-2">
            Course by
            <span className="trainer_name ms-1">
              {props.upcomingCourse.edu_info.user_info.first_name}
            </span>
          </span> */}

          <span className="star-rating">
            <StartRating avg_rating={props.upcomingCourse.avg_rating} />
          </span>
          {/* Session count, duration etc details */}
          {/* <div className="course_features-wrapper">
            <span className="course_features d-flex ">
              <LazyLoadImage src={clock} width={20} height={20} alt="clock" />
              <span className="ms-1">
              

                {moment(props.upcomingCourse.duration, "HH:mm:ss").format("H [H] m [M]")}
                
              </span>
            </span>
            <span width={20} className="course_features  d-flex">
              <LazyLoadImage src={status} width={20} height={20} alt="status" />
              <span className="ms-1">
                {props.upcomingCourse.session_count}
                {props.upcomingCourse.session_count > 1 ? "D" : "D"}

                <span className="text-capitalize">
                
                  {props.course}
                
                </span>
              </span>
            </span>
            <span className="course_features  d-flex">
              <LazyLoadImage src={certificate} width={20} height={20} alt="certificate" />
              <span className="ms-1">Certificate</span>
            </span>
          </div> */}

          <button
            className="btn book-btn align-self-center my-3 m w-100"
            target="_blank"
            onClick={() => {
              redirectToCourseDetails(props.upcomingCourse.id, props.upcomingCourse.title);
            }}
          >
            {props.btnName}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeCardItem;
