import React from "react";
import "./allcomps/css/child.css";

function Childcomp(props) {
  return (
    <div className="childcomp">
      <div className="container">
        {/* main heading sub heading and paragraph */}
        {Object.keys(props).map((items, index) => (
          <div key={index} className="d-flex">
            <div className="col-md-6 first-column">
              <div className="container">
                <div className="header">
                  <header>{props[items].title}</header>
                </div>
                <div className="sub-header">{props[items].subtitle}</div>
                <div className="para">{props[items].para}</div>
              </div>
            </div>
            <div className="col-md-6 second-column">
              <div className="container">
                <img className="image" src={props[items].image} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Childcomp;
