import React, { useEffect, useState } from "react";
import "../../style/newPlacement.css";
import Header from "../Header";
import ReqCallImg from "../../images/reqCallImg.png";
// import Infy from "../../images/‌infy.png";
import Test_sapana from "../../images/Test_sapana.png";
import placementBg from "../../images/placementBg.png";
import HowItWorks from "../../images/HowItWorks.png";
import WhatNext from "../../images/WhatNext.png";
import WhyNeedCoachx from "../../images/WhyNeedCoachx.jpeg";
import Prev1 from "../../images/Prev1.png";
import Next1 from "../../images/Next1.png";
import placementRandomImg from "../../images/placementRandomImg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import StartRating from "../StartRating";
import { useDispatch, useSelector } from "react-redux";
import { placementListAction } from "../../Action/PlacementListAction";
import Footer from "../Footer";
import Mainhead from "./Mainhead";
// material ui
import DoubleArrowSharpIcon from "@material-ui/icons/DoubleArrowSharp";

//API
// import axios from "axios";

export default function NewPlacement(attendFreeClassBtn) {
  const [pageNo, setpageNo] = useState(1);
  const [TotalCount, setTotalCount] = useState();
  const [ListCount, setListCount] = useState(0);
  const [listNo, setlistNo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const { stuList } = useSelector((state) => state.placementList);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(placementListAction);
  }, []);

  useEffect(() => {
    // window.scrollTo(0, 0);
    dispatch(placementListAction(pageNo));
  }, [pageNo]);

  useEffect(() => {
    setTotalCount(stuList?.studentsPlacementList?.count);
    setListCount(stuList?.studentsPlacementList?.count / 12);
  }, [stuList]);

  // Loading the items while infinite scrolling function

  // const handleScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight ||
  //     isLoading
  //   ) {
  //     return;
  //   }
  //   dispatch(placementListAction);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [isLoading]);

  // setIsLoading(true);

  // Loading infinite scrolling ends here..
  const Headerstyle = {
    // background: `linear-gradient(90deg, #005068 23.96%, rgba(91, 95, 117, 0) 55.9%), url(${placementBg})`,
    background: "none",
  };
  const headerPara4Style = {
    // width: "1024px",
    border: "none !important",
    textAlign: "center",

    // marginLeft:"7rem",
  };
  // const headerHeading8Style = {
  //   fontFamily: "Inter",
  //   fontWeight:800,
  //   fontSize: "40px",
  //   lineHeight: "40px",
  //   textAlign:"center",
  // }

  const placement = [
    {
      name: "Harry",
      desc: "Coachx.Live is happy to share the success stories of our students. For detailed report you can check here.Coachx.Live is happy to share the success stories of our students. For detailed report you can check here",
    },
    {
      name: "Cherry",
      desc: "Coachx.Live is happy to share the success stories of our students. For detailed report you can check here",
    },
    {
      name: "John",
      desc: "Coachx.Live is happy to share the success stories of our students. For detailed report you can check here",
    },
  ];

  const videoTestimonials = [
    {
      id: 1,
      video_id: "cB3w7syHdAY",
    },
    {
      id: 2,
      video_id: "6I74c654_b0",
    },
    {
      id: 3,
      video_id: "0h2466b_aR0",
    },
    {
      id: 4,
      video_id: "cWYBogD-ugk",
    },
    {
      id: 5,
      video_id: "_9MWwh7zkWo",
    },
    {
      id: 6,
      video_id: "w9SPTRB2OJk",
    },
    {
      id: 7,
      video_id: "j44sBHSg_LY",
    },
    {
      id: 8,
      video_id: "dv6SQSxQTNk",
    },
    {
      id: 9,
      video_id: "Ta-JNkOyv2g",
    },
    {
      id: 10,
      video_id: "2nR22CF6HNk",
    },
    {
      id: 11,
      video_id: "O-MKw-f2PM4",
    },
    {
      id: 12,
      video_id: "fcCz27N-fj0",
    },
    {
      id: 13,
      video_id: "yhraxQzMrXY",
    },
    {
      id: 14,
      video_id: "ff-6eMF5zo0",
    },
    {
      id: 15,
      video_id: "q7TZa5YDIC8",
    },
    {
      id: 16,
      video_id: "ohSK3jD7o4s",
    },
  ];

  return (
    <div className="SkyBlueGround">
      <Mainhead />
      {/* <Header
        Headerstyle={Headerstyle}
        headerTitle8={"Career Transformation"}
        headerPara7={
          <p style={headerPara4Style}>
            "At CoachX, we understand the power of real success stories from our learners. Watch
            these inspiring video testimonials and placement stories to hear directly from our proud
            learners about how they’ve elevated their careers with our guidance. Their journeys
            demonstrate the real impact we’ve had & how we can help you achieve your career goals
            too. Ready to take your career to the next level? Explore our programs and take the
            first step toward your career transformation today."
          </p>
        }
        attendFreeClassBtn={"Success Stories"}
      /> */}
      

      <div className="container  ">
        {/* <h4 className="my-5 LightBlueTitle" id="successStories">
          Success Stories
        </h4> */}
        <p>
          Celebrating Success.
          <br />
          We believe that helping one student is helping one family helping one generation. At
          Coachx, we take pride in our student’s achievement and we're delighted to have played a
          part in their journey towards accomplishing their goals!
        </p>
        {/* Google Reviews */}
        {/* <div className="google-reviews">
          {axios.get(
            "https://mybusiness.googleapis.com/v4/accounts/2435981140271238031/locations/{locationId}/reviews"
          )}
        </div> */}

        {/* Students list fetching from API of placements */}
        <div className="row my-5">
          {stuList?.studentsPlacementList?.results?.length == 0 && (
            <h2 className="text-center">No Students Found</h2>
          )}
          {stuList?.studentsPlacementList?.length > 0 &&
            stuList?.studentsPlacementList?.map((stu, i) => (
              <div className="col-lg-3 col-md-4 col-12">
                <div className="card coaches-card placement-coaches-card m-4">
                  <div className="card-body">
                    <div className="coaches-header d-flex flex-column justify-content-evenly align-items-center placement-header">
                      <div
                        key={i}
                        className="coaches-img-wrapper placement-coaches-img-wrapper d-flex justify-content-center"
                      >
                        <LazyLoadImage
                          src={stu?.profile_pic}
                          effect="blur"
                          className="coaches-img placement-coaches-img"
                          alt="coach.img"
                        />
                      </div>
                      <span className="">
                        <StartRating avg_rating={stu.rating} />
                      </span>
                      <div className="d-flex flex-column justify-content-between ">
                        <p className="coaches-college text-center mt-2">
                          <strong className="text-center mt-2 placementStuName">
                            {stu.stud_name}
                          </strong>
                        </p>

                        <div className="coaches-company text-center mt-2 designation">
                          {stu.designation}
                        </div>

                        <p className="comp_logo text-center">{stu.company_name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* Next and previous button of placed students */}
          {/*<div className="d-flex justify-content-center">
            <button
              className="btn btn-primary prev-btn mt-4"
              // disabled={pageNumber <= 1 ? true : false}
              onClick={handlePrevPage}
            >
              <span className="prev-arrow">
                <DoubleArrowSharpIcon />
              </span>
              Previous
            </button>
            <button
              className="btn btn-primary next-btn ms-2 mt-4"
              // disabled={DisplayData?.count / 10 < pageNumber}
              onClick={handleNextPage}
            >
              Next
              <span>
                <DoubleArrowSharpIcon />
              </span>
            </button>
          </div> */}
          <h4 className="LightBlueTitle my-5">What does our learners say about Placement?</h4>
          <div className="row my-5">
            <div className="col-12 col-md-6 random_image_wrapper ">
              <div className="placement_image_wrapper ">
                <img src={placementRandomImg} className="paraImg" alt="placementRandomImg" />
                <img src={placementRandomImg} alt="placementRandomImg" className="childImg1" />
                <img src={placementRandomImg} className="childImg2" alt="placementRandomImg" />
                <img src={placementRandomImg} className="childImg3" alt="placementRandomImg" />
              </div>
            </div>

            <div className="col-12 col-md-6 p-3">
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                <div className="carousel-inner">
                  {/* {videos && videos.length > 1 && ( */}
                  {stuList?.studentsPlacementList?.length > 0 &&
                    stuList?.studentsPlacementList?.map((stu, i) => (
                      // {stuList?.studentsPlacementList?.results?.length > 0 &&
                      //   stuList?.studentsPlacementList?.results.map((stu, i) => (
                      <div
                        className={
                          i === 0
                            ? "video-card carousel-item active  SliderBody"
                            : "video-card carousel-item SliderBody"
                        }
                      >
                        {/* <video id="pause1" controls>
                          <source src={videos[0].media_1} type="video/mp4" />
                        </video> */}
                        <p>{stu.review} </p>
                        <p>-{stu.stud_name}</p>
                        {/* <p>{stu.desc} </p>
                        <p>-{stu.name}</p> */}
                      </div>
                    ))}
                </div>

                <div className="mb-0">
                  <div className=" prevbtn">
                    <span
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="prev"
                      // onClick={pause}
                    >
                      <span
                        //   onClick={pause}
                        className="carousel-control-prev-icon bg-primary"
                        aria-hidden="true"
                      >
                        <img src={Prev1} alt="prev1" />
                      </span>
                    </span>
                  </div>
                  <div className="nextbtn">
                    <span
                      // onClick={pause}
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="next"
                    >
                      <span className="carousel-control-next-icon bg-primary" aria-hidden="true">
                        <img src={Next1} alt="prev1" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Our Students video testimonials */}

        <div className="videotestimonials">
          <h2>Hear What Our Students Say About Us</h2>
          {videoTestimonials?.map((items, i) => (
            <div className="testimonials d-inline-flex m-5" key={i}>
              <iframe
                id={`pause${items.id}`}
                width="320"
                height="180"
                src={`https://www.youtube.com/embed/${items.video_id}`}
              ></iframe>
            </div>
          ))}
        </div>
        <div className="whatNext row">
          {/* <div className="col-12 mt-4 mb-2">
                    <img
                      effect="blur"
                      src={HowItWorks}
                      width="100%"
                      alt="HowItWorks"
                    />
              </div> */}
          <div className="col-12 mt-4 mb-2">
            <img effect="blur" src={WhyNeedCoachx} width="100%" alt="WhatNext" />
          </div>
        </div>
      </div>
      <div className="placement_footer_wrapper">
        <Footer />
      </div>
    </div>
  );
}
