import React from "react";
import "../../components/NewPlacement/mainhead.css";

function Mainhead() {
  return (
    <div className="main-header">
      <div className="container">
        <div className="header text-lg-center">
          <span>
            <h2>Career Transformation</h2>
          </span>
          <span>
            <p>
              At CoachX, we understand the power of real success stories from our learners. Watch
              these inspiring video testimonials and placement stories to hear directly from our
              proud learners about how they’ve elevated their careers with our guidance. Their
              journeys demonstrate the real impact we’ve had & how we can help you achieve your
              career goals too. Ready to take your career to the next level? Explore our programs
              and take the first step toward your career transformation today
            </p>
          </span>
          <span>
            <button className="btn btn-success-stories">
              <a className="m-1 anchor-success" href="#successStories">Success Stories</a>
              <i className="bi bi-arrow-right"></i>
            </button>
          </span>
        </div>
      </div>
      <div className="heading-success">
        {/* <h4 className="my-4 LightBlueTitle" id="successStories">
          Success Stories
        </h4> */}
      </div>
    </div>
  );
}

export default Mainhead;
