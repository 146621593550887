import React from "react";
import "../component/allcomps/css/accorcomp.css";

function Accorcomp({ compElements, isSelected, setIsselected }) {
  return (
    <div>
      {compElements.map((items, index) => {
        return (
          <button
            className={isSelected == index ? "selected-btn" : "btn"}
            onClick={() => {
              setIsselected(index);
            }}
            key={index}
          >
            <div className="button">{items}</div>
          </button>
        );
      })}
    </div>
  );
}

export default Accorcomp;
