import React, { useEffect, useState } from "react";
import "../../style/home/card.css";
import courseImage from "../../images/cardImage.png";
import handwriting from "../../images/handwriting.png";
import googleAdd from "../../images/googleAdd.png";
import Slider from "react-slick";
import HomeCardItem from "./HomeCardItem";

export default function HomeCard({ btnName, upcomingCourses, course }) {
  var settings = {
    dots: true,
    arrows: false,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.0,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="course-slider-container">
        <Slider {...settings}>
          {
            // upcomingCourses && upcomingCourses.map((upcomingCourse, index) => {
            // return <HomeCardItem key={index} propsObj={propsObj}/>
            // })

            upcomingCourses &&
              upcomingCourses.map((upcomingCourse, index) => {
                return (
                  <HomeCardItem
                    key={index}
                    btnName={btnName}
                    upcomingCourse={upcomingCourse}
                    course={course}
                  ></HomeCardItem>
                );
              })
          }
        </Slider>
      </div>
    </div>
  );
}
