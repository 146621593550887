import React from "react";
import testimage from "../../../../images/services pics/testimage.png";
import Childcomp from "../Childcomp";

const itemsmain = {
  title: "The Best!",
  subtitle: "Wonder how our students communicate with us?",
  para: "Explore plethora of High Paying-Job Oriented courses and achieve your career goals easily with CoachX.Live today!",
  image: testimage,
};

function Comp3() {
  return (
    <div>
      <Childcomp data={itemsmain} />
    </div>
  );
}

export default Comp3;
