import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HomeCard from "../components/home/HomeCard";
import "../style/home/home.css";
import knowledge from "../images/expect_community.png";
import like from "../images/like.png";
import expect_community from "../images/expect_community.png";
import expect_certificate from "../images/expect_certificate.png";
import learnEasyImg from "../images/learnEasyImg2.png";
import videoTest from "../images/videoTest.jpeg";
import googlePlay from "../images/googlePlay.png";
import placementBg from "../images/home_banner.jpg";
// import placementBg from "../images/pooja_backend.jpeg";
import hiringImg from "../images/hiringImg.jpeg";
import ContactUs from "../components/ContactUs";
import { Link } from "react-router-dom";
import bhanu from "../images/Bhanu.jpeg";
import gajesh from "../images/Gajesh.jpeg";
import harish from "../images/Harish.jpeg";
import koushik from "../images/Koushik.jpeg";
import karthik from "../images/Karthik.jpeg";
import rohit from "../images/Rohit.png";
import whatsapp from "../images/whatsapp.png";
import lmsIcon from "../images/manageBatchLight.png";

//material ui
import Tab from "@mui/material/Tab";
import StarRateIcon from "@mui/icons-material/StarRate";
// swiper
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { upcomingCoursesAction, upcomingworkshopAction } from "../Action/upcomingCourses";
import { useSelector, useDispatch } from "react-redux";
import { catagoryAction } from "../Action/catagoryAction";
import { videoSliderAction } from "../Action/videoSlider";
import Slider from "react-slick";
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import RequestCallBack from "../components/RequestCallBack";
import CertificationCourses from "../components/CertificationCourses";
import Certi from "../components/Certi";

export default function Home() {
  const [slides, setslides] = useState("");
  const [toggle, settoggle] = useState(false);
  const [noOfSlides, setnoOfSlides] = useState();
  var myVideo1 = document.getElementById("pause1");
  var myVideo2 = document.getElementById("pause2");
  var myVideo3 = document.getElementById("pause3");
  function pause() {
    myVideo1.pause();
    myVideo2.pause();
    myVideo3.pause();
    myVideo1.currentTime = 0;
    myVideo2.currentTime = 0;
    myVideo3.currentTime = 0;
  }
  const { upcomingCourses } = useSelector((state) => state.upcomingcourse);
  const { upcomingWorkshop } = useSelector((state) => state.upcoingWorkshop);
  // const { videos } = useSelector((state) => state.sliederVideo);
  const { category } = useSelector((state) => state.catagories);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(upcomingCoursesAction());
    dispatch(upcomingworkshopAction());
    // dispatch(catagoryAction());
    dispatch(videoSliderAction());
  }, []);

  useEffect(() => {
    setnoOfSlides(category?.length > 6 ? 6 : category?.length);
    console.log(noOfSlides);
  }, [category?.length]);

  //react slick
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 200,
    slidesToShow: noOfSlides,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  // redirect to all courses page
  const redirectToAllCourseByCategory = (selectedCategory) => {
    window.location.href = window.location.origin + "/allCourses?category=" + selectedCategory;
  };

  const redirectToAllCourseByCourse = (courseType) => {
    window.location.href = window.location.origin + "/allCourses?course=" + courseType;
  };

  //videos of YouTube testimonals

  const testimonals = [
    {
      id: 1,
      video_id: "cWYBogD-ugk",
    },
    {
      id: 2,
      video_id: "Ta-JNkOyv2g",
    },
    {
      id: 3,
      video_id: "hbf0PBGyyTs",
    },
  ];

  //Coaches
  const coaches = [
    {
      img: karthik,
      name: "Karthik",
      des: "Sr. Developer (Google)",
      college: "IIT Mumbai",
    },
    {
      img: rohit,
      name: "Rohit",
      des: "Web 3.0 Blockchain",
      college: "IIT Mumbai",
    },
    {
      img: koushik,
      name: "Koushik",
      des: "Maryland, Ex-TCS R&D",
      college: "IIT Mumbai",
    },
    {
      img: bhanu,
      name: "Bhanu",
      des: "Full-Stack Developer",
      college: "IIT Mumbai",
    },
    {
      img: harish,
      name: "Harish",
      des: "Django Developer",
      college: "IIT Roorkee",
    },
    {
      img: gajesh,
      name: "Gajesh",
      des: "Sr. Dveloper (Ex-Byju's)",
      college: "IIT Roorkee",
    },
  ];

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      // cleanup function
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  useEffect(() => {
    if (width > 1023) {
      setslides(3.2);
    } else if (width > 767) {
      setslides(2.2);
    } else {
      setslides(1);
    }
  }, [width]);

  const Headerstyle = {
    background: `linear-gradient(90deg, #005068 23.96%, rgba(91, 95, 117, 0) 55.9%), url(${placementBg})`,
    Transform: `180deg`,
  };

  return (
    <div>
      <Header
        Headerstyle={Headerstyle}
        headerLearnNewKills={"Chasing Dreams Made Simple"}
        headerTitle1={"Master Any Skill "}
        headerTitle2={"From Industry Experts "}
        headerPara4={"IIT Mumbai Alumni Initiative"}
        headerLiveBtn={"LIVE "}
        headerPara1={"Explore "}
        headerPara6={"plethora of"}
        headerPara2={" courses and achieve your career goals easily "}
        headerPara3={"with CoachX.Live today!"}
        headerPara5={" High Paying-Job Oriented "}
        attendFreeClassBtn={"Talk To An Expert"}
      />
      <div className="home-wrapper">
        <div className="container">
          <div className="row ">
            <h3 className="course_category">Our Students Work At</h3>
            <div className="col-12 hiring-partners-outer-wrapper my-3">
              <LazyLoadImage src={hiringImg} effect="blur" alt="hiringImg" width="100%" />
            </div>
          </div>
        
        </div>
        <div className="contactUs-wrapper">
      
        </div>

        <div className="my-5">
          <div className="container my-4">
            <span className="course-Stream m-2">Upcoming Full Courses</span>
            <Link to="/allCourses?course=C" className="view-more">
              View more
            </Link>
          </div>
          <HomeCard
            btnName={"Ready to Join?"}
            upcomingCourses={upcomingCourses}
            course={"course"}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8  offset-md-2">
              <Link to="/allCourses">
                <button className="explore-btn">
                  Explore More CoachX Courses <i className="bi bi-arrow-right"></i>
                </button>
              </Link>
            </div>
            <hr className="hr-line" />
          </div>
          <div className="row my-5">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h4 className="whatToExpect-title">Why CoachX To Help You Upskill?</h4>
              </div>
            </div>
            <div className="col-lg-3">
              <LazyLoadImage src={expect_community} effect="blur" alt="expect_community" />
              <h4 className="expect_heading">Learn Relevant Skills & Advance Your Career</h4>
              <p className="expect_para">
                With live coaching, assessments, learning paths & courses authored by industry
                experts, our platform helps individuals build expertise in chosen paths without any
                barriers & achieve your career goals.
              </p>
            </div>
            <div className="col-lg-3">
              <LazyLoadImage src={expect_certificate} effect="blur" alt="expect_certificate" />
              <h4 className="expect_heading">Earn A Certificate & Enhance Your Credibility</h4>
              <p className="expect_para">
                Get a signed certificate to acknowledge the course completion, which you can easily
                share on your social media handles & even add to your resumes.Earn a certificate
                today & stand out among your peers.
              </p>
            </div>
            <div className="col-lg-3">
              <LazyLoadImage src={like} effect="blur" alt="like" />
              <h4 className="expect_heading">Learn Live & Join Our Community Of Learners</h4>
              <p className="expect_para">
                Through Live online classes we wish to bring our students in the best of both worlds
                where you can interact with classmates, ask the trainer questions, & enjoy social
                aspect of being with peers, making learning seamless.
              </p>
            </div>
            <div className="col-lg-3">
              <LazyLoadImage src={knowledge} effect="blur" alt="knowledge" />
              <h4 className="expect_heading">Learn From The Experts & Enrich Your Life</h4>
              <p className="expect_para">
                At CoachX, we bring you trainers who are experts in their fields, you can be certain
                when you are buying course that our Coaches will definitely help you master the
                skills to enhance your professional growth.
              </p>
            </div>
          </div>
          <hr className="hr-line" />
          <div className="row testimonal-wrapper  my-5">
            <div className="col-md-6  d-flex flex-column justify-content-center">
              <div>
                <span className="testimonal">Testimonials </span>
                <h4 className="testimonal_heading">
                  Take a look at what learners have to say about CoachX
                </h4>
                <p className="testimonal_para">
                  Make sure you are investing in the right platform! See what our learners have to
                  say before you enroll.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="video-wrapper">
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                  <div className="carousel-indicators ">
                    <div
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    >
                      .
                    </div>
                    <div
                      type="button"
                      className=" btn-primary"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                    >
                      .
                    </div>
                    <div
                      type="button"
                      className=" btn-primary"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="2"
                      aria-label="Slide 3"
                    >
                      .
                    </div>
                  </div>
                  <div className="carousel-inner">
                    {/* {testimonals && testimonals.length > 1 && (
                      <div className="video-card carousel-item active">
                        <video id="pause1" poster={videoTest} controls>
                          <source src={testimonals[0].link} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    {testimonals && testimonals.length > 1 && (
                      <div className="video-card carousel-item ">
                        <video id="pause2" poster={videoTest} controls>
                          <source src={testimonals[1].link} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    {testimonals && testimonals.length > 2 && (
                      <div className=" video-card carousel-item">
                        <video id="pause3" poster={videoTest} controls>
                          <source src={testimonals[2].link} type="video/mp4" />
                        </video>
                      </div>
                    )} */}
                    {/* {videos && videos.length > 1 && (
                      <div className="video-card carousel-item active">
                        <video id="pause1" poster={videoTest} controls>
                          <source src={videos[0].media_1} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    {videos && videos.length > 1 && (
                      <div className="video-card carousel-item ">
                        <video id="pause2" poster={videoTest} controls>
                          <source src={videos[1].media_2} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    {videos && videos.length > 2 && (
                      <div className=" video-card carousel-item">
                        <video id="pause3" poster={videoTest} controls>
                          <source src={videos[2].media_3} type="video/mp4" />
                        </video>
                      </div>
                    )} */}
                    {testimonals?.map((items, i) => (
                      <div
                        className="video-card carousel-item active ytp-cued-thumbnail-overlay ytp-large-play-button ytp-cued-thumbnail-overlay-image"
                        key={i}
                      >
                        <iframe
                          id={`pause${items.id}`}
                          width="640"
                          height="360"
                          src={`https://www.youtube.com/embed/${items.video_id}`}
                        ></iframe>
                      </div>
                    ))}
                  </div>

                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                    onClick={pause}
                  >
                    <span
                      onClick={pause}
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden ">Previous</span>
                  </button>
                  <button
                    onClick={pause}
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mentor-main">
          <div className="container position-relative">
            <div className="mentor-text">
              <span className="share-knowledge">Share Your Knowledge With The World</span>
              <h2 className="mentor-heading">Become a Trainer On CoachX </h2>
              <p className="mentor-para">
                If you are an expert in your field, start teaching on CoachX today and earn
                additional revenue from the comfort of your home. No upfront or hidden charges: You
                pay from what you earn!
              </p>
              <Link to="/becomeEducator">
                <button className="btn enroll-btn">Join Now</button>
              </Link>
            </div>
          </div>
        </div>
        {/* //COACHES  */}
        <div className="container coach-team">
          <h3 className="course-feedback mt-5">Meet Your Career Coaches-Get The Career Clarity</h3>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={0}
            slidesPerView={5}
            // dots={true}
            pagination={{ clickable: true }}
            slidesPerView={slides}
          >
            {coaches?.map((coach, index) => (
              <SwiperSlide className="swiper mb-5">
                <div className="card coaches-card">
                  <div className="card-body">
                    <div className="coaches-header d-flex flex-column justify-content-between align-items-center">
                      <div className="coaches-img-wrapper">
                        <LazyLoadImage
                          src={coach.img}
                          effect="blur"
                          className="coaches-img"
                          alt="coach.img"
                        />
                      </div>
                      <strong className="text-center mt-2">{coach.name}</strong>
                      <span className="coaches-college text-center mt-2">{coach.college}</span>
                      <span className="coaches-company text-center mt-2">{coach.des}</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="container">
          <div className="row easyLearn-wrapper le-wrapper ">
            <div className="col-md-6 d-flex py-0 flex-column  justify-content-center">
              <div className="">
                <h3 className="easyLearn-heading">Learn Easily From Any Place</h3>
                <p className="easyLearn-para">
                  A robust teaching-learning platform dedicated for you to access all the session
                  recordings, making your learning journey more valuable & skill-centric. “24*7”
                  access to your e-content.
                </p>
                <div className=" googleplay-wrapper">
                  <a
                    href="https://coachxlive-s-school.teachable.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="recording-btn-Home ps-4 my-1"
                  >
                    Your Ultimate LMS
                  </a>
                  {/* <a
                    href="https://play.google.com/store/apps/details?id=com.classboat.coachx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LazyLoadImage
                      src={googlePlay}
                      effect="blur"
                      className="easyLearn-googlePlay"
                      alt="googlePlay"
                    />
                  </a> */}

                  <p className="home-box-para mx-1 my-1 d-flex justify-content-center align-items-center flex-column">
                    <span className="">Google Reviews</span>
                    <span className="d-flex justify-content-center align-items-center">
                      <span className="me-1">4.8</span>
                      <StarRateIcon sx={{ color: "#E7B008", width: 18 }} />
                      <StarRateIcon sx={{ color: "#E7B008", width: 18 }} />
                      <StarRateIcon sx={{ color: "#E7B008", width: 18 }} />
                      <StarRateIcon sx={{ color: "#E7B008", width: 18 }} />
                      <StarRateIcon sx={{ color: "#E7B008", width: 18 }} />
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6  mobile-wrapper  position-relative d-flex justify-content-end  d-none d-sm-block">
              <LazyLoadImage
                src={learnEasyImg}
                effect="blur"
                className="mobile"
                alt="learnEasyImg"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
