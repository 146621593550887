import React, { useState, useEffect } from "react";
import "../style/courseDetail.css";
// import { Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import test from "../images/test.jpeg";
import HomeIcon from "../images/HomeIcon.png";
import rightArrow from "../images/rightArrow.png";
import clock from "../images/clock.png";
import status from "../images/status.png";
import rupee from "../images/rupee.png";
import Level from "../images/Level.png";
import hiringImg from "../images/hiringImg.jpeg";
import shareBtnIcon from "../images/share-btn-icon.png";
import videoCamera from "../images/videoCamera.svg";
import microphone from "../images/microphone.png";
import certificate from "../images/certificate.png";
import googlePlay from "../images/googlePlay.png";
import liveClasses from "../images/liveClasses.png";
import video from "../images/video.png";
import calendar from "../images/calendar.svg";
import slidervideo from "../";
import learnLiveImg1 from "../images/learnLiveImg-1.png";
import learnLiveImg2 from "../images/learnLiveImg-2.png";
import learnLiveImg3 from "../images/learnLiveImg-3.png";
import learnLiveImg4 from "../images/learnLiveImg-4.png";
import learnLiveImg5 from "../images/learnLiveImg-5.png";
import learnLiveImg6 from "../images/learnLiveImg-6.png";
import languageLogo from "../images/languageLogo.png";
import newTrainer from "../images/newTrainer.jpeg";
import logo from "../images/BLC_ inverse.png";
import loader from "../images/loader.gif";
import learnEasyImg from "../images/learnEasyImg.png";
import rayzorpay from "../images/RazorpayImg.png";
import monyGaurantee from "../images/monyGarauntee.svg";
import whatsapp from "../images/whatsapp.png";
import expect_community from "../images/expect_community.png";
import expect_certificate from "../images/expect_certificate.png";
import aboutUs_box_1 from "../images/aboutUs_box_1.png";
import aboutUs_box_3 from "../images/aboutUs_box_3.png";
import aboutUs_box_2 from "../images/aboutUs_box_2.png";
import feature_3 from "../images/feature_3.png";
import feature_5 from "../images/feature_5.png";
import knowledge from "../images/knowledge.png";
import like from "../images/like.png";
import daRoadmap from "../images/daRoadmap.jpeg";
import dsRoadmap from "../images/dsRoadmap.jpeg";
import BARoadmap from "../images/BARoadmap.jpeg";
import AWSRoadmap from "../images/AWSRoadmap.jpeg";
import savingIcon from "../images/savingIcon.png";
import img800 from "../images/800X800.png";

// images
import vinod from "../images/vinod_k.jpeg";
import kavya from "../images/kavya.jpeg";
import sapana_h from "../images/Sapana_h.jpeg";
import rohit_l from "../images/rohit_l.jpeg";
import virendra from "../images/virendra.jpeg";
import Amogh from "../images/f-1.jpeg";
import Tarun from "../images/f-2.jpeg";
import Nagaiah from "../images/f-3.jpeg";
import Nishant from "../images/f-4.jpeg";
import Chootu from "../images/f-5.jpeg";

//coaches

import bhanu from "../images/Bhanu.jpeg";
import gajesh from "../images/Gajesh.jpeg";
import harish from "../images/Harish.jpeg";
import koushik from "../images/Koushik.jpeg";
import karthik from "../images/Karthik.jpeg";
import rohit from "../images/Rohit.png";

import StartRating from "../components/StartRating";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";

// materia ui
import StarIcon from "@mui/icons-material/Star";
import Modal from "@material-ui/core/Modal";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
// import Backdrop from '@material-ui/core/Backdrop';
// import { useSpring, animated } from 'react-spring/web.cjs';
// swiper
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import HomeCard from "../components/home/HomeCard";
import { useSelector, useDispatch } from "react-redux";
//import { courseDetailsAction } from '../Action/courseDetailsAction'
import { upcomingCoursesAction, upcomingworkshopAction } from "../Action/upcomingCourses";
import { courseDetailsAction } from "../Action/courseDetailsAction";

import moment from "moment";
import axios from "axios";
import Navbar from "../components/Navbar";
import { requestCallAction } from "../Action/requestCallAction";
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";
import RequestCallBack from "../components/RequestCallBack";
import { feedbackAction } from "../Action/feedbakAction";

//Created payment details of component as new component
import Paymentdetail from "./coursedetail/Paymentdetail";
import Codeqr from "../images/qr.jpeg";

//payment options component

import Payment from "./paymentoptions/Payment";

export default function CourseDetail() {
  const shareLink = (link) => {
    //window.location=link;
    window.open(link, "_blank");
  };
  const { courseDetails, isLoading } = useSelector((state) => state.course);
  const { feedback } = useSelector((state) => state.feedback);
  console.log(feedback);
  const [userDetails, setuserDetails] = useState(false);
  const [slides, setslides] = useState("");
  const [feedbackSlides, setfeedbackSlides] = useState("");
  const [toggle, settoggle] = useState(true);
  const [courseFrequency, setCourseFrequency] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const [userData, setUserData] = useState([]);

  //download broucher's lead form state variables

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [enquiry, setenquiry] = useState("");
  const [GraduationYear, setGraduationYear] = useState("");
  const [query, setQuery] = useState(
    "I am interested to know more about " + courseDetails?.courseData?.title
  );
  const [ShowFeesStructure, setShowFeesStructure] = useState(false);

  // custom hook for getting current screen width

  const search = useLocation().search;
  // const course_info = new URLSearchParams(search).get("course_info");
  const course_info = window.location.search.split("=")[1];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(courseDetailsAction(course_info));
    dispatch(upcomingCoursesAction());
    dispatch(upcomingworkshopAction());
  }, []);

  const { upcomingCourses } = useSelector((state) => state.upcomingcourse);
  const { upcomingWorkshop } = useSelector((state) => state.upcoingWorkshop);
  const { requestCall } = useSelector((state) => state.reqCall);
  const [brochureToggle, setBrochureToggle] = useState(false);

  const keyFeatures = [
    {
      img: aboutUs_box_2,
      feature: "100% Live Interactive Learning",
    },
    {
      img: aboutUs_box_1,
      feature: "100% Placement Assistance",
    },
    {
      img: aboutUs_box_3,
      feature: "Complete Session Recordings",
    },
    {
      img: expect_certificate,
      feature: "Industry Recognized Certificate Upon Course Completion",
    },
    {
      img: feature_3,
      feature: "Easy EMI Payment Options",
    },
    {
      img: feature_5,
      feature: "24 X 7 Lifetime Support",
    },
    {
      img: knowledge,
      feature: "Hands-On Project Based Learning",
    },
    {
      img: like,
      feature: "Interview Preparation Guidance",
    },
    {
      img: like,
      feature: "Linked In profile Building Guidance",
    },
  ];

  // req call back

  const handleDownloadBroucher = (e) => {
    e.preventDefault();
    dispatch(
      requestCallAction({
        name,
        email,
        mobile: mobileNo,
        query: enquiry,
        year_of_graduation: GraduationYear,
      })
    );
  };

  useEffect(() => {
    dispatch(feedbackAction());
  }, []);

  useEffect(() => {
    if (requestCall?.status == "0x0") {
      setuserDetails(false);
      window.open(courseDetails?.courseData?.brochure, "_blank");
    }
  }, [requestCall]);

  //responsive feedback card

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      // cleanup function
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return size;
  }

  const [height, width] = useWindowSize();
  useEffect(() => {
    if (width > 1023) {
      setslides(3.2);
      setfeedbackSlides(2.2);
    } else if (width > 767) {
      setslides(2.2);
      setfeedbackSlides(1.2);
    } else {
      setslides(1);
      setfeedbackSlides(1);
    }
  }, [width]);

  ///////////////////////////////////////////////////////

  const [display, setdisplay] = useState(false);
  const showMoreOrLess = () => {
    setdisplay(!display);
  };

  useEffect(() => {
    CourseAvailability();
  }, [courseDetails]);

  // course availability
  const CourseAvailability = (data) => {
    let tmp = [];
    if (data && data.frequency && data.frequency.length > 0) {
      data.frequency.forEach((element) => {
        let day = new Date(element).getDay();
        tmp.push(parseInt(day));
      });
      setCourseFrequency(tmp);
      setSelectedBatch(data);
    }
  };

  const [courseLevel, setCourseLevel] = useState("");

  useEffect(() => {
    if (courseDetails) {
      if (courseDetails?.courseData?.course_level === "B") {
        setCourseLevel("Beginner Level");
      }

      if (courseDetails?.courseData?.course_level === "E") {
        setCourseLevel("Expert Level");
      }
      if (courseDetails?.courseData?.course_level === "I") {
        setCourseLevel("Intermediate Level");
      }
    }
  }, [courseDetails]);

  const [selectedBatchIndex, setSelectedBatchIndex] = useState(0);

  useEffect(() => {
    // if (
    //   courseDetails &&
    //   courseDetails?.batch_info &&
    //   courseDetails?.batch_info.length > 0
    // ) {
    //   let index = courseDetails?.batch_info.findIndex(
    //     (x, index) =>
    //       new moment(x.start_date) > new moment() && x.seats_available > 0
    //   );
    //   if (index == -1) {
    //     index = 0;
    //   }
    //   setSelectedBatchIndex(index);
    //   CourseAvailability(courseDetails?.batch_info[index]);
    // }
  }, [courseDetails]);

  //Coaches

  const coaches = [
    {
      img: karthik,
      name: "Karthik",
      des: "Sr. Developer (Google)",
      college: "IIT Mumbai",
    },
    {
      img: rohit,
      name: "Rohit",
      des: "Web 3.0 Blockchain",
      college: "IIT Mumbai",
    },
    {
      img: koushik,
      name: "Koushik",
      des: "Maryland, Ex-TCS R&D",
      college: "IIT Mumbai",
    },
    {
      img: bhanu,
      name: "Bhanu",
      des: "Full-Stack Developer",
      college: "IIT Mumbai",
    },
    {
      img: harish,
      name: "Harish",
      des: "Django Developer",
      college: "IIT Roorkee",
    },
    {
      img: gajesh,
      name: "Gajesh",
      des: "Sr. Dveloper (Ex-Byju's)",
      college: "IIT Roorkee",
    },
  ];

  //redirect to payment page

  const history = useHistory();
  const redirectToPaymentPage = () => {
    history.push("/coupenCodeForm?selectedBatch=" + selectedBatch.id);
  };

  //redirect to lead form

  const redirectToLeadForm = () => {
    history.push("/leadForm?course_info=" + course_info);
  };

  //feedback

  const feedback1 = [
    {
      image: kavya,
      name: "Kavita dakhare",
      review:
        "I took a course of Power BI certification training course from CoachX. With my new skill I can creat dashboards and reports for my projects.  The course is well structured and pretty interactive.",
      rating: 4.9,
      company: "DelphianLogic Technologies",
    },
    {
      image: rohit_l,
      name: "Rohit Lichade",
      review:
        "I have completed a course of the Full Stack Web Development at Coachx.live, which was really a great experience, trainers are so supportive and the batch was reciprocal. Once I mastered the skill set I got all the placement assistance required. They really exceeded my expectations.",
      rating: 4.8,
      company: "Nexdigm",
    },
    {
      image: vinod,
      name: "Vinod Kapade.",
      review:
        "I am genuinely glad I enrolled for CoachX training program, being a working professional I was looking for a flexible program for upskilling and honing more skills. Specially Live plus recorded sessions helped me to learn at my own pace. Thanks to CoachX for high quality training content and bringing in excellent trainers for every module.",
      rating: 5.0,
      company: "Canwill Technologies",
    },
    {
      image: sapana_h,
      name: "Sapana Hosmath.",
      review:
        "I've completed my training in Power BI and Data Analytics at CoachX the curriculum and program is very structured and easy to consume. The mock interview did really help me with my actual job interviews. Entire program really uplifted my professional skills and overall confidence, I'm extremely happy to recommend CoachX.",
      rating: 4.7,
      company: "Birla soft",
    },
    {
      image: virendra,
      name: "Virendra Singh",
      review:
        "I would say choosing CoachX program is worth the investment I made on myself. This is very well structured course and specially weekend batches did not disturb my work schedule. Recordings helped me to recover and revise content. Internal assessments and score cards keep us focused on the course, specially in this remote world, it was wonderful learning experience.",
      rating: 4.9,
      company: "TCS",
    },
    {
      image: Amogh,
      name: "Amogh Pujar",
      review:
        "One can blindly decide to go with CoachX, just like you I was worried to go ahead with this program, but experience was excellent, content delivery, interview support, interview preparation sessions, everything helped me to move step by step towards my goal. Thank you CoachX.",
      rating: 4.5,
      company: "Extrieve",
    },
    {
      image: Tarun,
      name: "Tarun Prajapati",
      review:
        "Everyday doubt solving sessions, community access, helpdesk support, placement support, quality content and dedicated trainers is all we need when we chose to upskill, and I would happily say CoachX provides everything whole-heartedly.  I do recommend CoachX to all my peers.",
      rating: 4.5,
      company: "BYJU'S",
    },
    {
      image: Nagaiah,
      name: "Nagaiah Naga",
      review:
        "With excellent content and well defined learning methodologies, I could shape up my career as Business Analyst with the help of CoachX.Live, I am happy to recommend this live learning platform to everyone who has dreamed of this career. Thank you Team CoachX.",
      rating: 4.5,
      company: "JP Morgan Chase Co.",
    },
    {
      image: Nishant,
      name: "Nishant Singh",
      review:
        "I am one of the CoachX learner and I chose to pursue data analytics with them, however as soon as I completed my first module in Excel, I started my job search and successfully bagged the position of MIS Executive I am still continuing my course and hoping for bright future as content is extremely quality and so the trainers.If you want to embark on a career of your dreams, I recommend CoachX as they are extremely student centric.",
      rating: 4.5,
      company: "Sharda Exports",
    },
    {
      image: Chootu,
      name: "Chootu Prajapati",
      review:
        "Industry experienced professional trainers , well defined road map, curated content and placement assistance overall it was enriching experience. Looking at the current technology era it is mandatory for everyone to upskill and invest on your own to stay ahead of the curve, and CoachX.Live makes that investment worth. Specially I would like to mention in co-learing students come with varied background and patience shown by trainers was phenomenal.",
      rating: 4.5,
      company: "Accenture",
    },
  ];

  //  modal

  const handleOpen = () => {
    setuserDetails(true);
  };

  const handleClose = () => {
    setuserDetails(false);
  };

  const feesStructure = [
    {
      title: "One Time Payment",
      desc: "hello",
      regFess: "",
      inst1: "",
      inst2: "",
      inst3: "",
      oneTime: 24000,
      discount: 30,
      noOfInst: 1,
    },

    {
      title: "One Installment",
      desc: "hello",
      regFess: 5000,
      inst1: 21000,
      inst2: "",
      inst3: "",
      oneTime: "",
      discount: 25,
      noOfInst: 1,
    },

    {
      title: "Two Installments",
      desc: "hello",
      regFess: 5000,
      inst1: 13000,
      inst2: 12000,
      inst3: "",
      totalFees: "",
      oneTime: "",
      discount: 20,
      noOfInst: 2,
    },
    {
      title: " Three Installments",
      desc: "hello",
      regFess: 5000,
      inst1: 10000,
      inst2: 10000,
      inst3: 10000,
      oneTime: "",
      discount: 0,
      noOfInst: 3,
    },
  ];

  const courseFeesStructure = [
    "Complete Data Analytics Course",
    "Professional Certificate Program in Data Science",
    "AWS Certified Cloud Practitioner - Complete Course",
    "Professional Certificate Program in  Business Analytics",
  ];

  const handleshowFeesStructure = () => {
    setShowFeesStructure((prev) => !prev);
  };

  return (
    <>
      {isLoading ? (
        <div className="loader mt-5">
          <img effect="blur" src={loader} alt="loader" />
        </div>
      ) : (
        <div className="courseDetail-wrapper">
          <div className="container courseDetail-navigation-wrapper">
            <Link to="/" className="link">
              <img effect="blur" src={HomeIcon} alt="HomeIcon" />
              <span className="mx-2 navigation-page">Home</span>
            </Link>
            <img effect="blur" src={rightArrow} alt="rightArrow" />
            <Link to="/allCourses" className="link">
              <span className="mx-2 navigation-page">Courses</span>
            </Link>
            <img effect="blur" src={rightArrow} alt="rightArrow" />
            <Link className="link">
              <span className="mx-2 navigation-page">{courseDetails?.courseData?.category}</span>
            </Link>
            <img effect="blur" src={rightArrow} alt="rightArrow" />
            <span className="mx-2 navigation-page">{courseDetails?.courseData?.title}..</span>
            <h3 className="master-performance-heading">{courseDetails?.courseData?.title}</h3>
            <p className="master-perforance-para">{courseDetails?.courseData?.summary}</p>
            <div className="row">
              {/* <div className="wrapper-row"> */}

              <div className="col-lg-6  col-xxl-6  col-md-12 col-12  mt-1  wrapper-column">
                <div className="courseDetails-img-wrapper ">
                  {toggle ? (
                    <img
                      effect="blur"
                      src={courseDetails?.courseData?.course_image}
                      // src={img800}
                      className="course-video courseDetails-img"
                      alt="no img"
                    />
                  ) : (
                    <video className="course-video" height={100} controls></video>
                  )}
                </div>
              </div>

              <div className="col-lg-6 col-xxl-6   col-md-12 col-12 mt-1 mt-md-1 wrapper-column">
                <div className="course-highlights  ms-0">
                  <h3 className="course-highlights-heading">Course Highlights</h3>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      <img effect="blur" src={videoCamera} alt="videoCamera" />{" "}
                      <span className="course-heighlights-inner-headings">Live sessions</span>
                    </p>
                    <span>{courseDetails?.courseData?.session_count}</span>
                  </div>
                  <div className="batch-scheduled-wrapper">
                    <p>
                      <img effect="blur" src={calendar} alt="calendar" />
                      <span className="course-heighlights-inner-headings ms-1">Batch Schedule</span>
                    </p>
                    <div className="scrollable-wrapper">
                      <p
                        className={`batch-schedule d-flex justify-content-between align-items-center selected-batch`}
                        // onClick={() => {
                        //   setSelectedBatchIndex(index);
                        //   CourseAvailability(
                        //     courseDetails?.batch_info[index]
                        //   );
                        // }}
                      >
                        <span>Upcoming Batch</span>
                        <span>16 seats left</span>
                      </p>
                      {/* {courseDetails?.batch_info &&
                        courseDetails?.batch_info.map((batch, index) => {
                          if (batch.seats_available > 0) {
                            return (
                              <p
                                className={`batch-schedule d-flex justify-content-between align-items-center ${
                                  index == selectedBatchIndex
                                    ? " selected-batch"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSelectedBatchIndex(index);
                                  CourseAvailability(
                                    courseDetails?.batch_info[index]
                                  );
                                }}
                              >
                                <span>Upcoming Batch</span>
                                <span>
                                  ({batch.seats_available} seats left)
                                </span>
                              </p>
                            );
                          }
                        })} */}
                    </div>
                    {/* <p className='d-flex'><span className='sroll-batches'>Scroll for more batches</span></p> */}
                    <p className="mt-2">
                      <img effect="blur" src={calendar} alt="calendar" />
                      <span className="course-heighlights-inner-headings ms-1">
                        Days Of The Week
                      </span>
                    </p>
                    <p className="d-flex ms-2">
                      <span className="weekdays-all-circle d-flex ms-4">
                        <div
                          className={`sroll-batches day-box weekdays-circles ${
                            courseFrequency &&
                            courseFrequency.length > 0 &&
                            courseFrequency.indexOf(0) > -1
                              ? "day-box"
                              : ""
                          }`}
                        >
                          <span>S</span>
                        </div>
                        <div
                          className={`sroll-batches weekdays-circles ${
                            courseFrequency &&
                            courseFrequency.length > 0 &&
                            courseFrequency.indexOf(1) > -1
                              ? "day-box"
                              : ""
                          }`}
                        >
                          <span>M</span>
                        </div>
                        <div
                          className={`sroll-batches weekdays-circles ${
                            courseFrequency &&
                            courseFrequency.length > 0 &&
                            courseFrequency.indexOf(2) > -1
                              ? "day-box"
                              : ""
                          }`}
                        >
                          <span>T</span>
                        </div>
                        <div
                          className={`sroll-batches weekdays-circles ${
                            courseFrequency &&
                            courseFrequency.length > 0 &&
                            courseFrequency.indexOf(3) > -1
                              ? "day-box"
                              : ""
                          }`}
                        >
                          <span>W</span>
                        </div>
                        <div
                          className={`sroll-batches weekdays-circles ${
                            courseFrequency &&
                            courseFrequency.length > 0 &&
                            courseFrequency.indexOf(4) > -1
                              ? "day-box"
                              : ""
                          }`}
                        >
                          <span>T</span>
                        </div>
                        <div
                          className={`sroll-batches weekdays-circles ${
                            courseFrequency &&
                            courseFrequency.length > 0 &&
                            courseFrequency.indexOf(5) > -1
                              ? "day-box"
                              : ""
                          }`}
                        >
                          <span>F</span>
                        </div>
                        <div
                          className={`sroll-batches day-box weekdays-circles ${
                            courseFrequency &&
                            courseFrequency.length > 0 &&
                            courseFrequency.indexOf(6) > -1
                              ? "day-box"
                              : ""
                          }`}
                        >
                          <span>S</span>
                        </div>
                      </span>
                    </p>
                    <div className="d-flex m-0 justify-content-between align-items-center">
                      <p>
                        <img effect="blur" src={status} alt="status" />{" "}
                        <span className="course-heighlights-inner-headings">Mode Of Delivery</span>
                      </p>
                      <p>Live Sessions</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p>
                        <img
                          effect="blur"
                          src={languageLogo}
                          alt="videoCamera"
                          width="25"
                          className="me-1"
                        />
                        <span className="course-heighlights-inner-headings">Language</span>
                      </p>
                      <div>
                        <span>{courseDetails?.courseData?.language}</span>
                      </div>
                    </div>
                    <button className="bookNow-btn w-100" onClick={redirectToLeadForm}>
                      Register Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contactUs">
            {/* <ContactUs /> */}
            {/* <RequestCallBack/> */}
          </div>
          {/* <a href="https://wa.me/message/YIUPH6AA6FGUF1" target="_black">
            {" "}
            <img
              effect="blur"
              src={whatsapp}
              className="me-2 wpChat"
              alt="whatsapp"
            />
          </a> */}
          {/* /// */}
          <div className="learn-together-wrapper mt-4">
            <div className="container learn-together-inner-wrapper">
              <span className="learn-together">Need Career Guidance? </span>
              <a
                className="btn dropdown-item ps-0 talkToExpertBtn"
                href="https://wa.me/message/YIUPH6AA6FGUF1"
                target="_black"
              >
                Talk To An Expert
              </a>
            </div>
          </div>
          {/* /// */}
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-10 offset-lg-1">
                <div className="about-courses-wrapper">
                  <div className="row">
                    <div className="col-md-7 px-auto d-flex justify-content-center flex-column">
                      <h3 className="about-course-title">About Course</h3>
                      <p className="about-courses-para">{courseDetails?.courseData?.description}</p>
                    </div>
                    <div className="col-md-5">
                      <p>
                        <img effect="blur" className="m-1" src={Level} alt="level" />
                        <span className="about-courses-feature-text">Level : {courseLevel}</span>
                      </p>
                      <p>
                        <img effect="blur" className="m-1" src={videoCamera} alt="videoCamera" />
                        <span className="about-courses-feature-text">
                          Live Session : {courseDetails?.courseData?.session_count}
                        </span>
                      </p>
                      <p>
                        <img effect="blur" className="m-1" src={clock} alt="clock" />{" "}
                        <span className="about-courses-feature-text">
                          Time : 120 {/* {courseDetails?.session_info?.duration} mins */}
                          each
                        </span>
                      </p>
                      <p className="ms-1">
                        <img effect="blur" className="m-1" src={microphone} alt="microphone" />{" "}
                        <span className="about-courses-feature-text ps-1">
                          Language : {courseDetails?.courseData?.language}
                        </span>
                      </p>
                      <p className="ms-1">
                        <img effect="blur" className="m-1" src={certificate} alt="level" />{" "}
                        <span className="about-courses-feature-text ps-1">
                          Certificate of Completion
                        </span>
                      </p>
                    </div>
                    <div className="col-12">
                      <p>
                        <img effect="blur" src={calendar} alt="calendar" />
                        <span className="course-heighlights-inner-headings  ms-2">
                          Days Of The Week
                        </span>
                      </p>
                      <p className="d-flex ms-2">
                        <span className="weekdays-all-circle d-flex ms-4">
                          <div
                            className={`sroll-batches day-box weekdays-circles ${
                              courseFrequency &&
                              courseFrequency.length > 0 &&
                              courseFrequency.indexOf(0) > -1
                                ? "day-box"
                                : ""
                            }`}
                          >
                            <span>S</span>
                          </div>
                          <div
                            className={`sroll-batches weekdays-circles ${
                              courseFrequency &&
                              courseFrequency.length > 0 &&
                              courseFrequency.indexOf(1) > -1
                                ? "day-box"
                                : ""
                            }`}
                          >
                            <span>M</span>
                          </div>
                          <div
                            className={`sroll-batches weekdays-circles ${
                              courseFrequency &&
                              courseFrequency.length > 0 &&
                              courseFrequency.indexOf(2) > -1
                                ? "day-box"
                                : ""
                            }`}
                          >
                            <span>T</span>
                          </div>
                          <div
                            className={`sroll-batches weekdays-circles ${
                              courseFrequency &&
                              courseFrequency.length > 0 &&
                              courseFrequency.indexOf(3) > -1
                                ? "day-box"
                                : ""
                            }`}
                          >
                            <span>W</span>
                          </div>
                          <div
                            className={`sroll-batches weekdays-circles ${
                              courseFrequency &&
                              courseFrequency.length > 0 &&
                              courseFrequency.indexOf(4) > -1
                                ? "day-box"
                                : ""
                            }`}
                          >
                            <span>T</span>
                          </div>
                          <div
                            className={`sroll-batches weekdays-circles ${
                              courseFrequency &&
                              courseFrequency.length > 0 &&
                              courseFrequency.indexOf(5) > -1
                                ? "day-box"
                                : ""
                            }`}
                          >
                            <span>F</span>
                          </div>
                          <div
                            className={`sroll-batches day-box weekdays-circles ${
                              courseFrequency &&
                              courseFrequency.length > 0 &&
                              courseFrequency.indexOf(6) > -1
                                ? "day-box"
                                : ""
                            }`}
                          >
                            <span>S</span>
                          </div>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-4 mb-2">
                  <img
                    effect="blur"
                    src={courseDetails?.courseData?.roadmap}
                    width="100%"
                    alt="daRoadmap"
                  />
                </div>

                <div className="syllabus-wrapper border">
                  <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between mb-2">
                    <h3 className="syllabus-heading mt-2">Program Curriculum</h3>
                    {courseDetails?.courseData?.brochure === null ? (
                      ""
                    ) : (
                      <button className="btn btn-info download-btn text-white" onClick={handleOpen}>
                        Download Broucher
                      </button>
                    )}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: courseDetails?.courseData?.syllabus,
                    }}
                  ></div>
                </div>
                {/* This key features are those things provided in the course like placement assistance etc */}
                <div className="keyFeatures-wrapper my-2">
                  <h3 className="course-feedback my-4">Key Course Features</h3>
                  <div className="row">
                    {keyFeatures.map((item, i) => (
                      <div className="col-lg-4 col-md-6 box-wrapper ">
                        <div className="feature-header-box">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-2">
                              <img
                                effect="blur"
                                src={item.img}
                                className="mb-3"
                                alt="aboutUs_box_1"
                              />
                            </div>
                            <div className="col-10">
                              <p className="feature-box-para ps-3">
                                <span className="feature-text">{item.feature} </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ==============Trainer========== */}

          <div className="about-speaker-wrapper">
            <div className="container">
              <div className="row d-flex flex-md-colunm">
                <div className="col-lg-4 col-md-12 d-flex align-items-lg-center justify-content-lg-center flex-column">
                  <img
                    effect="blur"
                    src={newTrainer}
                    className="speaker-image align-self-center"
                    alt="no img"
                  />
                </div>
                <div className="col-lg-8 col-md-12">
                  <span className="about-speaker">Meet Our Trainers</span>
                  {/* <h3 className="speaker-name">
                    {courseDetails?.edu_info?.user_info?.first_name}
                  </h3> */}
                  <p className="speaker-para">
                    {/* {courseDetails?.edu_info?.summary} */}
                    Our learners are pride, so we are always on the lookout to give you the best.
                    Hence, we regularly access our trainers to ensure they impart quality learning.
                    All the trainers we have on board come with vast experience & robust knowledge.
                    Our trainers always ensure that you grasp the right knowledge & skills to enable
                    you to grow seamlessly and achieve all the goals that you have set for yourself.
                  </p>
                  <p className="speaker-para">
                    {/* {courseDetails?.edu_info?.summary} */}
                    We have a unique training module where we offer all our students real-time and
                    applicable scenarios, live learning and the best interactive experience to make
                    learning effortless. Our trainers avoid jargon and help you understand the core
                    concepts in easy-to-understand language so anyone & everyone can effectively
                    grasp the syllabus.
                  </p>
                  <p className="speaker-para">
                    We also ensure our students are able to complete their learnings in a
                    hassle-free manner & help them prepare for interviews. We facilitate wholesome
                    learning & our students enjoy the freedom to clear their doubts and ask
                    questions in every learning session. We help you learn and open doors to new
                    career opportunities!
                  </p>
                  {/* {courseDetails?.edu_info?.teaching_domains &&
                    courseDetails?.edu_info.teaching_domains.map(
                      (domain, index) => (
                        
                      )
                    )} */}
                  <button className="speaker-btns">Live Learning</button>
                  <button className="speaker-btns">Careers</button>
                  <button className="speaker-btns">Subject Matter Experts</button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="about-speaker-wrapper">
            <div className="container">
              <div className="row d-flex flex-md-colunm">
                <div className="col-lg-4 col-md-12 d-flex align-items-lg-center     justify-content-lg-center flex-column">
                  <img
                    effect="blur"
                    src={courseDetails?.edu_info?.profile_pic}
                    className="speaker-image"
                    alt="no img"
                  />
                </div>
                <div className="col-lg-8 col-md-12">
                  <span className="about-speaker">Meet Our Trainer</span>
                  <h3 className="speaker-name">
                    {courseDetails?.edu_info?.user_info?.first_name}
                  </h3>
                  <p className="speaker-para">
                    {courseDetails?.edu_info?.summary}
                  </p>
                  {courseDetails?.edu_info?.teaching_domains &&
                    courseDetails?.edu_info.teaching_domains.map(
                      (domain, index) => (
                        <button key={index} className="speaker-btns">
                          {domain}
                        </button>
                      )
                    )}
                </div>
              </div>
            </div>
          </div> */}
          {/* ============================================================================ */}
          <div className="certificate-wrapper">
            <div className="">
              <div className="row">
                <div className="col-lg-10 col-md-12 offset-lg-1">
                  <h3 className="certificate-title">
                    Yes! You will be certified once you complete this course
                  </h3>
                  <p className="certificate-para">
                    Do use this certificate to enhance your professional credibility and stand out
                    among your peers!
                  </p>
                  <div className="certificate-frame">
                    <img
                      effect="blur"
                      src={
                        courseDetails?.courseData?.course_dummy_certificate == ""
                          ? courseDetails?.courseData?.dummy_certificate
                          : courseDetails?.courseData?.course_dummy_certificate
                      }
                      alt="no img"
                      className="dummy-certificate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* -------------------Course Detail Page New Component-------------------------- */}

          {/* <div className="courseFees-wrapper">
                        <div className="container">
                          <div className="col-md-4"></div>
                          <div className="col-md-4"></div>
                          <div className="col-md-4"></div>
                        </div>
                      </div> */}
          {/* Payment options component */}
          {/* <Payment /> */}
          {/*=========================Course Detail page new component===================*/}
          {/* <Paymentdetail /> */}
          {/* /////////////// Course Fees ////////////// */}
          <div className="courseFees-wrapper">
            <div className="container">
              {/* <<<<<<<< money back garauntee >>>>>>>>>  */}
              {/* <div className="gaurantee mb-4">
                <div className="container">
                  <div className="row w-100 d-flex align-items-center">
                    <div className="col-md-3 pe-md-4  py-2 d-flex justify-content-center">
                      <img
                        effect="blur"
                        src={monyGaurantee}
                        width="150"
                        alt="monyGaurantee"
                      />
                    </div>
                    <div className="col-md-9 px-md-0 d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start py-2">
                      <p className="gaurantee-para">
                        Coachx sessions are highly engaging interactive,
                        outcome-centric, complete live led by expert faculty.
                        Our training programs are empowering thousands everyday
                        to build the in-demand skills and creating most skilled
                        workforce for the nation. If you enrol and be
                        disappointed for any reason, you'll get your 100% refund
                        – If you're not satisfied within your first 3 days then
                        we'll refund you without any arguments or any hidden
                        rules.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="">
                <div className="courseFee payent-option">
                  <div className="row">
                    <div className="col-12 ms-lg-4 d-flex justify-content-between flex-column flex-lg-row">
                      <div className="col-md-4">
                        <h2>Payment Option</h2>
                        <p className="mt-3">
                          For One-Time payment users <br /> we are having following Options
                        </p>
                        <img
                          effect="blur"
                          src={rayzorpay}
                          width="200"
                          className="razorpayImg"
                          alt="rayzorpay"
                        />
                        <div className="Scholorship d-flex flex-column justify-content-center align-items-center">
                          <a href="https://rzp.io/l/Uj7dsVRRPM" target="_blank">
                            <button className="scholarshipBtn ">Register Now With 5k</button>
                          </a>
                        </div>
                      </div>
                      {/*===============================================qr code scanner======================================  */}
                      <div className="col-md-4 qr-code">
                        <img src={Codeqr} alt="img" width={180} />
                      </div>
                      {/* ====================================================================== */}
                      <div className="d-flex col-md-4 flex-column">
                        <div>
                          <span className="course-heighlights-inner-headings fw-bold fs-md-3 fs-9 me-1">
                            <img effect="blur" src={rupee} alt="rupee" /> Course Price:
                          </span>
                          {courseDetails?.courseData?.discount == 0 ? (
                            <span className="discount-price ">Free</span>
                          ) : (
                            <>
                              <span className="discount-price mt-3 fs-md-3 fs-9">
                                {courseDetails?.courseData?.discounted_fees}
                              </span>
                              <span className="original-price mt-1 fs-md-3 fs-9">
                                {courseDetails?.courseData?.fees}
                              </span>
                            </>
                          )}
                        </div>
                        <div className="mt-3">
                          <p>Internet Banking, Debit/Credit cards,Upi payments</p>
                          <p>
                            <strong>Get upto 30% OFF</strong>, How? Contact us to know.
                          </p>
                          <div className="Scholorship d-flex flex-column justify-content-center align-items-center">
                            <a href="https://rzp.io/l/Uj7dsVRRPM" target="_blank">
                              <button className="scholarshipBtn bookNowNavColor">Book Now</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-evenly align-items-center flex-column">
                      <div>
                        {/* <span className="course-heighlights-inner-headings fw-bold fs-md-3 fs-9">
                          <img effect="blur" src={rupee} alt="rupee" /> Course
                          Price:
                        </span> */}
                        {/* {courseDetails?.discount == 0 ? (
                          <span className="discount-price ">Free</span>
                        ) : (
                          <>
                            <span className="discount-price mt-3 fs-md-3 fs-9">
                              {courseDetails?.discount}
                            </span>
                            <span className="original-price mt-1 fs-md-3 fs-9">
                              {courseDetails?.fees}
                            </span>
                          </>
                        )} */}
                      </div>
                      {/* <a href="https://rzp.io/l/Uj7dsVRRPM" target="_blank">
                      <button
                        className="courseFee-btn"
                        // onClick={redirectToPaymentPage}
                      >
                        Book Now
                      </button>
                      </a> */}
                    </div>
                  </div>

                  <div className="d-lg-flex reg5k justify-content-between">
                    {/* <div className="courseFee  Scholorship d-flex flex-column justify-content-center align-items-center">
                      <a href="https://rzp.io/l/Uj7dsVRRPM" target="_blank">
                        <button className="scholarshipBtn ">Register Now With 5k</button>
                      </a>
                    </div> */}

                    {/* <div className="courseFee Scholorship d-flex flex-column justify-content-center align-items-center">
                      <a href="https://rzp.io/l/Uj7dsVRRPM" target="_blank">
                        <button className="scholarshipBtn bookNowNavColor">Book Now</button>
                      </a>
                    </div> */}
                    {/* {courseFeesStructure.includes(courseDetails?.courseData?.title) && ( */}
                    {/* // <div className="courseFee Scholorship d-flex flex-column justify-content-center align-items-center"> */}
                    {/* <a href="https://rzp.io/l/Uj7dsVRRPM"> */}
                    {/* <button className="scholarshipBtn btn5k" onClick={handleshowFeesStructure}>
                          Click Here To Know More
                        </button> */}
                    {/* </a> */}
                    {/* // </div> */}
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* =========== fees structure ========= */}

          {ShowFeesStructure && (
            <div className="faq-wrapper border animate__animated animate__slideInDown">
              <h3 className="faq-title">Number Of Installments And Fees Structure</h3>

              <div className="row">
                {feesStructure?.map((item, i) => (
                  <div className="col-12  col-sm-6  col-lg-3 card-column my-2">
                    <div className="card fees-card ">
                      <div className="card-header">
                        <h6>{item.title}</h6>
                      </div>
                      <div className="card-body">
                        <p className="fees-titles">
                          <LocalOfferOutlinedIcon className="PriceIcon" /> Total Fees:{" "}
                          <CurrencyRupeeIcon fontSize="small" className="ruppeIcon" />{" "}
                          {Math.round(
                            courseDetails?.courseData?.discounted_fees -
                              (item.discount / 100) * courseDetails?.courseData?.discounted_fees
                          )}
                        </p>
                        <p className="fees-titles">
                          {" "}
                          <img src={savingIcon} alt="savingIcon" width="28" /> Discount:{" "}
                          {item.discount}%
                        </p>
                        <ul>
                          {item.regFess !== "" && (
                            <li>
                              <p className="feesStuPara">
                                Registration: <CurrencyRupeeIcon className="ruppeIcon" />{" "}
                                {Math.round(item.regFess)}
                              </p>
                            </li>
                          )}
                          {item.inst1 !== "" && (
                            <li>
                              <p className="feesStuPara">
                                Installment Starts at:
                                <CurrencyRupeeIcon fontSize="small" className="ruppeIcon" />
                                {Math.round(
                                  (courseDetails?.courseData?.discounted_fees -
                                    (item.discount / 100) *
                                      courseDetails?.courseData?.discounted_fees -
                                    item.regFess) /
                                    item.noOfInst
                                )}{" "}
                              </p>
                            </li>
                          )}
                          {/* {item.inst2 !==""   &&<li>
                          <p>Installment-2: {item.inst2}</p>                        
                        </li>}
                        {item.inst3 !=="" &&<li>
                          <p>Installment-3: {item.inst3}</p>     
                        </li>
                        } */}
                          {item.oneTime !== "" && (
                            <li>
                              <p className="feesStuPara">
                                Discounted Fees:{" "}
                                <CurrencyRupeeIcon fontSize="small" className="ruppeIcon" />{" "}
                                {Math.round(
                                  courseDetails?.courseData?.discounted_fees -
                                    (item.discount / 100) *
                                      courseDetails?.courseData?.discounted_fees
                                )}
                              </p>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* ======================== */}
          <div className="learn-together-wrapper mt-4 border">
            <div className="container learn-together-inner-wrapper">
              <span className="learn-together">Having Financial Problem?</span>
              <Link to="/leadForm">
                <button className="btn pay_now">Talk To us for scholarship</button>
              </Link>
            </div>
          </div>

          {/* //COACHES  */}
          <div className="container coach-team">
            <h3 className="course-feedback mt-5">
              Meet Your Career Coaches-Get The Career Clarity
            </h3>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={0}
              slidesPerView={5}
              // dots={true}
              pagination={{ clickable: true }}
              slidesPerView={slides}
              // navigation={true}
            >
              {coaches?.map((coach, index) => (
                <SwiperSlide className="swiper mb-5">
                  <div className="card coaches-card">
                    <div className="card-body">
                      <div className="coaches-header d-flex flex-column justify-content-between align-items-center">
                        <div className="coaches-img-wrapper">
                          <img
                            effect="blur"
                            src={coach.img}
                            className="coaches-img"
                            alt="coach.img}"
                          />
                        </div>
                        <strong className="text-center mt-2">{coach.name}</strong>
                        <span className="coaches-college text-center mt-2">{coach.college} </span>
                        <span className="coaches-company text-center mt-2">{coach.des}</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* ======= FAQ ======= */}

          <div className="faq-wrapper border">
            <h3 className="faq-title">FAQs</h3>
            {/* ACORDIAN    */}
            {courseDetails?.faq &&
              courseDetails?.faq.map((faq, i) => (
                <div className="accordion  accordion-flush" id={"accordian-flush" + i}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={"flush-heading" + i}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#flush-" + i}
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        {faq.question}
                      </button>
                    </h2>
                    <div
                      id={"flush-" + i}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent={"#accordian-flush" + i}
                    >
                      <div className="accordion-body">
                        <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* ////////////////// course feedback ////////////// */}

          {/* <div className="course-feedback-wrapper">
            <h3 className="course-feedback"> Course Feedback</h3>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={0}
              pagination={{ clickable: true }}
              slidesPerView={feedbackSlides}
            >
              {feedback?.results?.map((feedback, index) => (
                <SwiperSlide className="swiper">
                  <div className=" swiper-card">
                    <div className="card-body">
                      <div className="slider-header d-flex justify-content-between align-items-center">
                        <img
                          effect="blur"
                          src={feedback.profile_pic}
                          className="slider-img"
                          alt="feedback.image"
                        />
                        <span className="slider-rating">
                          {[...Array(Math.round(feedback.rating))].map((item, i) => (
                            <StarIcon key={i} sx={{ color: "#E7B008" }} />
                          ))}
                          {feedback.rating}
                        </span>
                      </div>
                      <p>
                        {feedback.student_name} |{" "}
                        <span className="name-company">{feedback.company_name}</span>
                      </p>
                      <p className="slider-para">{feedback.review}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}

          {/* //////////////// Learn Together ////////////////// */}
          <div className="learn-together-wrapper mt-0 ">
            <div className="container learn-together-inner-wrapper">
              <span className="learn-together">Learn together with your friends</span>
              <div>
                <button
                  className="share-btn"
                  onClick={() =>
                    shareLink(
                      "https://api.whatsapp.com/send?text=" +
                        "Hey! Checkout this amazing course " +
                        courseDetails.courseData?.title +
                        " By " +
                        // courseDetails.edu_info.user_info.first_name +
                        " on CoachX " +
                        courseDetails?.courseData?.share_link
                    )
                  }
                >
                  <img effect="blur" src={shareBtnIcon} alt="share" /> Share
                </button>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <h3 className="course_category">Our Students Work At</h3>
              <div className="col-12 hiring-partners-outer-wrapper my-3">
                <img effect="blur" src={hiringImg} alt="hiringImg" width="100%" />
              </div>
            </div>
          </div>
          {/* learn live section  */}
          <div className="live-teachers-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-5 d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <h3 className="live-teachers-title">Learn Live From The Best Teachers</h3>
                    <span className="download-app">and pre-recorded too!</span>
                    <a
                      href="https://coachxlive-s-school.teachable.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="recording-btn mx-1 my-1"
                    >
                      Your Ultimate LMS
                    </a>
                    {/* <img
                      effect="blur"
                      src={googlePlay}
                      className="google-play"
                      alt="googlePlay"
                    /> */}
                  </div>
                </div>
                <div className="col-md-7 d-flex flex-column justify-content-center align-itmes-center">
                  <div className="img-wrapper">
                    <img
                      effect="blur"
                      src={learnLiveImg1}
                      className="avtar avtar1 "
                      alt="learnLiveImg"
                    />
                    <img
                      effect="blur"
                      src={learnLiveImg2}
                      className="avtar avtar2 "
                      alt="learnLiveImg"
                    />
                    <img
                      effect="blur"
                      src={learnLiveImg3}
                      className="avtar avtar3 "
                      alt="learnLiveImg"
                    />
                    <img
                      effect="blur"
                      src={learnLiveImg4}
                      className="avtar avtar4 "
                      alt="learnLiveImg"
                    />
                    <img
                      effect="blur"
                      src={learnLiveImg5}
                      className="avtar avtar5 "
                      alt="learnLiveImg"
                    />
                    <img
                      effect="blur"
                      src={learnLiveImg6}
                      className="avtar avtar6 "
                      alt="learnLiveImg"
                    />
                  </div>
                  <div>
                    <img effect="blur" src={learnEasyImg} className="avtar7" alt="learnEasyImg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-5">
            <div className="row">
              <div className="course-heading-wrapper  d-flex justify-ontent-center align-items-center">
                <h3 className="free-course-heading">Upcoming Full Courses</h3>{" "}
                <span className="view-more">
                  <a href="#">View more</a>
                </span>
              </div>
              <HomeCard btnName={"Join Now"} upcomingCourses={upcomingCourses} />
            </div>
          </div>
          <Footer />
          <div className="position-relative">
            <div className="book-now-btn-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {selectedBatch.seats_available > 0 &&
                    moment(selectedBatch.start_date) > moment() ? (
                      <button className="book-now-btn disable" onClick={redirectToLeadForm}>
                        Register Now
                      </button>
                    ) : (
                      <button className="book-now-btn">Register Today</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* modal  */}
      <Modal
        open={userDetails}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="broucherBox" sx={{ width: 400 }}>
          <div className="crossIcon" onClick={handleClose}>
            <ClearIcon />
          </div>
          <div className="">
            <h4 className="text-center mb-5">Downlod Broucher</h4>
            <form onSubmit={handleDownloadBroucher}>
              <div className="my-4">
                <input
                  type="Name"
                  className="broucherInput"
                  placeholder="Name*"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  required
                />
              </div>
              <div className="my-4">
                <input
                  type="email"
                  className="broucherInput"
                  placeholder="Email*"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  required
                />
              </div>
              <div className="my-4">
                <input
                  type="number"
                  className="broucherInput"
                  placeholder="Contact No.*"
                  value={mobileNo}
                  onChange={(e) => setmobileNo(e.target.value)}
                  required
                />
              </div>
              <div className="my-4">
                <input
                  type="date"
                  className="broucherInput"
                  value={GraduationYear}
                  placeholder="Year of graduation in"
                  onChange={(e) => setGraduationYear(e.target.value)}
                  required
                />
              </div>
              <div className="my-4">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="2"
                  className="broucherInput"
                  placeholder="Work Experience*"
                  value={enquiry}
                  onChange={(e) => setenquiry(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="row mt-5">
                <div className="col-6 offset-3">
                  <button className="btn btn-info text-white my-2 broucherBtn" type="submit">
                    Download
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}
