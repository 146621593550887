import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import "../style/corporateTrainings.css";
import placementImg from "../images/placementImg.png";
import placementImg_2 from "../images/placementImg_2-min.png";
import placementImg_3 from "../images/placementImg_3-min.png";
import check from "../images/succesfullLogo.png";
import { requestCallAction } from "../Action/requestCallAction";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

//Accordion for the services we provide
import Renderelement from "../components/services-accordion/render/Renderelement";

export default function Placement() {
  const dispatch =useDispatch()
  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [mobileNo, setmobileNo] = useState("")
  const [query, setquery] = useState("")
  const [GraduationYear, setGraduationYear] = useState("")
  const search = useLocation().search;

  const course_info = new URLSearchParams(search).get("course_info");

  const { requestCall } = useSelector((state) => state.reqCall);

const  history =useHistory()

const reqCallBackAction=()=>{
    dispatch(requestCallAction({name,email,mobile:mobileNo,query,
      year_of_graduation:GraduationYear
    }))
}

useEffect(() => {
  if(requestCall.status=="0x0"){
    history.push("/thankYouForEnrollment?course_info=ThankYouPage")                                 
   }  
}, [requestCall])

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <div>
      <div className="coupenCode-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-first order-last p-0">
              <div className="placementImg-wrapper">
                <h4 className="corporateTraining-formHeading text-center">
                  Your search for the right candidates stops here{" "}
                </h4>
                <p className="text-center p-2 p-md-0">
                  Take the first step in hiring industry ready and right talent
                  from us
                </p>
                <LazyLoadImage src={placementImg} width="100%" height="100%" alt="placementImg" />
              </div>
            </div>
            <div className="col-lg-6 order-lg-last order-first">
              <div className="corporateTraining-form">
                <h4 className="corporateTraining-formHeading text-center">
                  HIRE FROM US FOR FREE
                </h4>
                <div>
                  <label className="leadLabel">Name Of The Person *</label>
                  <input
                    type="text"
                    className="form-control coupenInput3 mb-1"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e)=>setname(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Company Name & Hiring role*</label>
                  <input
                    type="text"
                    className="form-control coupenInput3 mb-1"
                    placeholder="Enter Company Name & Hiring role"
                    value={query}
                    onChange={(e)=>setquery(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Contact No. *</label>
                  <input
                    type="number"
                    max={10}
                    className="form-control coupenInput3 mb-1"
                    placeholder="Contact No."
                    value={mobileNo}
                    onChange={(e)=>setmobileNo(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Work Email *</label>
                  <input
                    type="email"
                    className="form-control coupenInput3 mb-1"
                    placeholder="Email ID"
                    value={email}
                    onChange={(e)=>setemail(e.target.value)}
                    required
                  />
                </div>
                <div>
                <label className="leadLabel">Date of meeting *</label>
                <input
                  type="date"
                  className="form-control coupenInput3 mb-4 mb-1"
                  placeholder="year of graduation"
                  value={GraduationYear}
                  onChange={(e)=>setGraduationYear(e.target.value)}
                  required
                  />
                </div>

                {/* <hr className="my-4"/> */}
                <div className="d-flex justify-content-center w-100 mt-4">
                  <button
                    className="corporateTraining-submit  mx-auto w-100"
                    onClick={reqCallBackAction}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Renderelement />
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-12">
              <div>
                <h4 className="corporateTraining-formHeading text-center">
                  WHY HIRE FROM COACHX
                </h4>
                <p className="text-center p-2 p-md-0">
                  Tap in to the right talent.
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="why-hire-para fs-5 ms-2 why-hire-para">
                    Hire From Pan-India Talent Pool.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">
                    Available for full time, Freelancing, Internship projects.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">
                    Hire Job Ready Professionals at No Cost!
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">Complete Zero Cost Hiring.</span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">
                    Dedicated Relationship Manager.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check"/>
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">Quick Turn Around Time.</span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>{" "}
                  <span className="fs-5 ms-2 why-hire-para">
                    Candidates who are Productive workforce from day 1.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>{" "}
                  <span className="fs-5 ms-2 why-hire-para">
                    Experience ranging from 0 to 12 years.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>{" "}
                  <span className="fs-5 ms-2 why-hire-para">
                    Available for full time, Freelancing, Internship projects.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>{" "}
                  <span className="fs-5 ms-2 why-hire-para">
                    Demonstrable Complex Projects and Assignments.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 p-0 placementImg_2-wrapper">
                <LazyLoadImage src={placementImg_2} className="placementImg_2"  alt="placementImg_2" />
                <LazyLoadImage src={placementImg_3} className="placementImg_3"  alt="placementImg_2" />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
